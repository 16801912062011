.homePage > p.error {
  color: #F06063;
}

.homePage > p.loading {
  color: #FAD713;
}

@media screen and (min-width: 1200px) {
  .homePage {
      max-width: 1100px;
      margin: 10px auto;
  }
}