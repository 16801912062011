* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img, video {
  max-width: 100%;
  height: auto;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 20px;
  color: #333;
}

html, body {
  width: 100%;
  height: 100%;
}